import './App.css';
import { useEffect, useState } from 'react';
import appstore from './appstore.png';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-LL2RE0Q9Y9');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  const [groupName, setGroupName] = useState(null);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let value = params.t;
    let decoded = decodeURIComponent(value)
    console.log(window.location.pathname);
    if (decoded !== "null") {
      setGroupName(decoded);
      document.title = decoded;
      logSession("TEST_GROUP_ID");
    }
    if (window.location.pathname !== "/") {
      const groupId = window.location.pathname.substring(1);
      logSession(groupId);
    }
  }, [])

  /**
   * So we can directly add user to a group
   */
  const logSession = (groupId) => {
    fetch('https://api.memein.com/uf/l', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "gid": groupId,
        "height": window.screen.height,
        "width": window.screen.width
      })
    });
    // fetch('http://127.0.0.1:5000/uf/l', {
    //   method: 'get',
    // });
  }

  const handleClick = () => {
    window.location = "https://apps.apple.com/us/app/unfiltered-chat/id1662383301";
  }

  return (
    <div className="App">
      <div style={{ top: -100, position: "relative" }}>
        {groupName === null ? (
          <div>
            <h2>
              Join a Chat on Unfiltered
            </h2>
          </div>
        ) : (<div>
          <p>You've been invited to</p>
          <h2>{groupName}</h2>
        </div>)}
        <button style={{ backgroundColor: "transparent", border: 0 }}>
          <img src={appstore} alt="App store image" onClick={handleClick} style={{ height: 40, width: 120 }} />
        </button>
      </div>
    </div>
  );
}

export default App;
